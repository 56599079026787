import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby";

import Seo from "../components/seo"
import Layout_page from "../components/layout_page";

const NotFoundPage = () => (

  <div className="bg-def_grey_1 page_formatting">

    <Layout_page>

      <h1 className="mt-20 text-white">404: Not Found</h1>
      <p className="text-neutral-300">Sidan kunde inte hittas...</p>
      <Link to="/" className="button_1 mt-10" href="/">Gå till Hem</Link>

    </Layout_page>
  </div>
)



export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
